import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer'

import { hasMoreValues } from '../../../../../lib/indicator';

const Styles = StyleSheet.create({
  indicator: {
    list: {
      marginBottom: 5,
      caption: {
      },
      value: {
        marginLeft: 5
      },
    }
  }
});

export default function IndicatorList({
  indicator,
}) {
  let values;

  if (!!indicator?.suitableValues && !!indicator?.valueClasses) {
    values = indicator.valueClasses
      .filter(v => indicator.suitableValues.some(s => s === v.code))
      .map(v => ({ ...v, hasMoreValues: hasMoreValues(v) }));

    // convert values without names
    if (values.some(v => !v.name)) {
      values = values.map(v => v.values).flaten();
    }
  }

  return (
    <View style={Styles.indicator.list}>
      {values?.map((value, indx) => (
        <View key={`indicator-list-value-${indx}`} style={Styles.indicator.list}>
          <Text style={Styles.indicator.list.caption}>
            {`${value.name}${!!value.caption ? ` [${value.caption}]` : ''}`}
          </Text>

          {value.hasMoreValues && value.values.map((val, vindx) => (
            <Text
              key={`value-${indx}-${vindx}`}
              style={Styles.indicator.list.value}
            >
              &bull; {val.name}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
}
import React from 'react';

// Components
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

const Styles = StyleSheet.create({
  hazard: {
    display: 'flex',
    flexDirection: 'column'
  }
});

export default function Hazard({
  hazard
}) {
  return (
    <Paragraph title="Hazard">
      <View style={Styles.hazard}>
        {hazard?.map(({ name, desc }, indx) => (
          <Text key={`hazard-${indx}`}>
            {name} {!!desc ? `(${desc})` : ''}
          </Text>
        ))}
      </View>
    </Paragraph>
  );
}
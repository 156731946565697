import React from 'react';

// Components
import Section from '../Section';
import Indicator from './Suitability/Indicator';

export default function Suitability({
  intervention
}) {
  return (
    <Section
      title="NBS Suitability Mapping"
      notes="(Below are the layers and specifications listed that were used for analysing the suitability of this Nature-based Solution for your area)"
    >
      {intervention.interventionIndicators.map((indicator, indx) => (
        <Indicator key={`indicator-${indx}`} indicator={indicator} />
      ))}
    </Section>
  );
}
import React from 'react';

// Components
import { StyleSheet, View, Text, Link } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

const Styles = StyleSheet.create({
  reference: {
    marginBottom: 3
  }
});

export default function References({
  interventionReferences
}) {
  return (
    <Paragraph title="References">
      {interventionReferences?.map((reference, indx) => (
        <View key={`reference-${indx}`} style={Styles.reference}>
          <Text>{reference.name}</Text>
          <Text>Available at: <Link src={reference.url}>{reference.url}</Link></Text>
        </View>
      ))}
    </Paragraph>
  );
}
import React from 'react';

// Components
import { Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

export default function Lifetime({
  nbsLifetime
}) {
  return (
    <Paragraph title="NBS Lifetime">
      <Text>{nbsLifetime}</Text>
    </Paragraph>
  );
}
import React, { createContext, useCallback, useContext } from 'react';

import { usePermissions } from '~/store/PermissionsProvider';

export const HelpContexts = ({
  home: 'home',
  register: 'register',
  nbsExplorer: 'nbs-explorer',
  login: 'login',
  oalExplorer: 'oal-explorer',
  stories: 'stories',
  map: 'map',
  policyCatalogue: 'policy-catalogue',
  permittingPath: 'permitting-path',
  businessNetworking: 'business-networking',
  toolkit: 'toolkit',
  nbsCatalogue: 'nbs-catalogue',
  dataCatalogue: 'data-catalogue'
});

const Help = createContext();

function HelpProvider({ helpContext, children }) {
  const permissions = usePermissions();

  const getHelpUrl = useCallback(() => {
    const helpInfo = permissions?.find(p => p.layout?.help?.context === helpContext)?.layout?.help;

    return helpInfo?.url;
  }, [permissions, helpContext]);

  const value = {
    helpContext,
    getHelpUrl
  };

  return (
    <Help.Provider value={value}>
      {children}
    </Help.Provider>
  );
}

function useHelp() {
  return useContext(Help);
}

export {
  Help,
  HelpProvider,
  useHelp
};
import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer'

const Styles = StyleSheet.create({
  section: {
    marginTop: 20,
    marginBottom: 20,
    caption: (hasNotes) => ({
      fontSize: 14,
      fontWeight: 'bold',
      marginBottom: hasNotes ? 5 : 10
    }),
    notes: (hasNotes) => ({
      fontSize: 10,
      opacity: 0.8,
      marginBottom: hasNotes ? 10 : 0
    }),
    content: {
    }
  }
});

export default function Section({
  title,
  notes,
  children
}) {
  return (
    <View style={Styles.section}>
      <Text style={Styles.section.caption(!!notes)}>{title}</Text>
      <Text style={Styles.section.notes(!!notes)}>{notes}</Text>
      <View style={Styles.section.content}>
        {children}
      </View>
    </View>
  );
}
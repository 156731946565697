import React from 'react';

import { IndicatorPresentationType } from '../../../InterventionPage/TabCards/IndicatorCard';

// Components
import Paragraph from '../../Paragraph';
import IndicatorListValue from './Indicator/ListValue';
import IndicatorSingleValue from './Indicator/SingleValue';

export default function Indicator({
  indicator
}) {
  const IndicatorComponent =
    indicator.presentationType === IndicatorPresentationType.list
      ? IndicatorListValue
      : indicator.presentationType === IndicatorPresentationType.singleValue
        ? IndicatorSingleValue
        : null;

  return (
    <React.Fragment>
      {!!IndicatorComponent && (
        <Paragraph title={indicator.attGroup}>
          <IndicatorComponent indicator={indicator} />
        </Paragraph>
      )}
    </React.Fragment>
  );
}
import React from 'react';

// Components
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

const Styles = StyleSheet.create({
  benefits: {
    marginBottom: 4,
    caption: {
      fontWeight: 'bold',
      marginBottom: 2
    },
    content: {
    }
  }
});

export default function Benefits({
  benefits,
  title
}) {
  return (
    <Paragraph title={title}>
      {benefits?.map((benefit, indx) => (
        <View key={`benefit-${indx}`} style={Styles.benefits}>
          <Text style={Styles.benefits.caption}>{benefit.name}</Text>
          <Text style={Styles.benefits.content}>{benefit.desc}</Text>
        </View>
      ))}
    </Paragraph>
  );
}
import React from 'react';

// Components
import Section from '../Section';

import NbsType from './Overview/NbsType';
import Approach from './Overview/Approach';
import Hazard from './Overview/Hazard';
import Sdgs from './Overview/Sdgs';
import Suitability from './Overview/Suitability';
import Costs from './Overview/Costs';
import Benefits from './Overview/Benefits';
import Policies from './Overview/Policies';
import Funding from './Overview/Funding';
import GreySolution from './Overview/GreySolution';

export default function Overview({
  intervention
}) {
  return (
    <Section title="Overview">
      {!!intervention?.nbsType && (
        <NbsType nbsType={intervention.nbsType} />
      )}

      {!!intervention?.approach?.length && (
        <Approach approach={intervention.approach} />
      )}

      {!!intervention?.hazard && (
        <Hazard hazard={intervention.hazard} />
      )}

      {/*<Paragraph title="Multi-hazard">
      </Paragraph>*/}

      {!!intervention?.nbsSdg && (
        <Sdgs nbsSdg={intervention.nbsSdg} />
      )}

      {!!intervention?.suitabilityMapSummary && (
        <Suitability suitabilityMapSummary={intervention.suitabilityMapSummary} />
      )}

      {!!intervention?.costs && (
        <Costs costs={intervention.costs} />
      )}

      {!!intervention?.directBenefit?.length && (
        <Benefits title="Direct Benefits" benefits={intervention.directBenefit} />
      )}

      {!!intervention?.cobenefit?.length && (
        <Benefits title="Co-benefits" benefits={intervention.cobenefit} />
      )}

      {!!intervention?.relatedPolicy?.length && (
        <Policies relatedPolicy={intervention.relatedPolicy} />
      )}

      {!!intervention?.funding && (
        <Funding funding={intervention.funding} />
      )}

      {!!intervention?.greySolution && (
        <GreySolution greySolution={intervention.greySolution} />
      )}
    </Section>
  );
}
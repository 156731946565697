import React, { createContext, useContext } from 'react';
import { createGlobalStyle } from 'styled-components';
import GuideChimp from 'guidechimp';

import 'guidechimp/dist/guidechimp.min.css';

const GlobalOverrides = createGlobalStyle`
  .gc-tooltip {
    border-radius: 0.75rem !important;
    overflow: hidden;
    background-color: var(--gprimary-desat-alt2) !important;
    max-width: 20rem !important;
  }

  .gc-progressbar {
    background-color: var(--gwarning) !important;
  }

  .gc-pagination-item {
    &.gc-pagination-active {
      background-color: var(--gwarning) !important;
    }
  }

  .gc-pagination-item {
    background-color: var(--gprimary-desat) !important;
  }

  .gc-navigation-prev,
  .gc-navigation-next {
    border-bottom: 5px solid var(--gprimary-desat) !important;
    border-right: 5px solid var(--gprimary-desat) !important;

    &:hover {
      border-color: var(--gwarning) !important;
    }
  }

  .gc-title {
    color: var(--gprimary-dark-alt) !important;
  }

  .gc-description {
    color: var(--gsecondary) !important;
  }

  .gc-close {
    &::before, 
    &::after {
      background-color: var(--gprimary-dark) !important;
    }
  }

  .gc-copyright {
    display: none;
  }
`;

const DefaultOptions = ({
  position: 'bottom',
  useKeyboard: true,
  exitEscape: true,
  exitOverlay: true,
  showPagination: true,
  showProgressbar: true,
  interaction: false,
  padding: 20,
  scrollPadding: 100,
  scrollBehavior: 'smooth'
});

const Guide = createContext();

function GuideProvider({ children }) {
  function start(tour, force, stepNumber = 0, useIndex = true) {
    const storageKey = `guide.tour.${encodeURIComponent(tour)}`;
    const completed = window.localStorage.getItem(storageKey);

    if (!completed || force) {
      window.localStorage.removeItem(storageKey);

      const guide = new GuideChimp(tour, DefaultOptions);

      guide.start(stepNumber, useIndex);
      guide.on('onComplete', function () {
        // the library emits onComplete in stop() when this.currentStepIndex === this.steps.length - 1
        // in case a react component, that calls provider.value.start() is unmounted before guide finds and initializes all staps,
        // the next call to start() will stop a previous incomplete instance, and so onComplete will be emitted,
        // it will writes local storage item, and so mext mount won't create an instance
        // this is why we have to check for currentStepIndex before we write a local storage item
        !!this.currentStepIndex && window.localStorage.setItem(storageKey, 'complete');
      });

      return guide;
    } else {
      return null;
    }
  }

  function startOnce(tour, stepNumber = 0, useIndex = true) {
    const guide = new GuideChimp(tour, DefaultOptions);

    guide.start(stepNumber, useIndex);
  }

  return (
    <Guide.Provider value={{ start, startOnce }}>
      <GlobalOverrides />
      {children}
    </Guide.Provider>
  );
}

function useGuide() {
  return useContext(Guide);
}

export {
  Guide,
  GuideProvider,
  useGuide
}
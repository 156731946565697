import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer'

const Styles = StyleSheet.create({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    title: {
      color: '#506690',
      fontSize: 12,
      fontWeight: 'bold',
      textAlign: 'center',
      paddingBottom: 5,
      marginBottom: 45,
      borderBottomWidth: 1,
      borderBottomStyle: 'dashed',
      borderBottomColor: '#506690',
    }
  }
});

export default function Header({
  title
}) {
  return (
    <View
      fixed
      style={Styles.header}
      render={({ pageNumber }) => pageNumber > 1
        ? (<Text style={Styles.header.title}>{title}</Text>)
        : null
      }
    />
  );
}
import React from 'react';
import { StyleSheet, Page, Font } from '@react-pdf/renderer'

// Components
import Header from './Document/Header';
import Footer from './Document/Footer';
import Title from './Document/Title';
import Overview from './Section/Overview';
import Design from './Section/Design';
import Suitability from './Section/Suitability';
import Sources from './Section/Sources';

Font.register({
  family: 'Raleway',
  fonts: [{
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf'
  }, {
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCP.ttf'
  }, {
    fontStyle: 'italic',
    fontWeight: 'normal',
    src: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQ.ttf'
  }]
});

const Styles = StyleSheet.create({
  page: {
    paddingTop: 50,
    paddingBottom: 100,
    paddingLeft: 60,
    paddingRight: 60,
    backgroundColor: '#FFF',
    fontSize: 10,
    color: '#4b5d81',
    fontFamily: 'Raleway'
  }
});

export default function Document({
  intervention
}) {
  const tabs = ({
    design: (!!intervention?.sizeShort /* size */ || !!intervention?.coCreation
      || !!intervention?.modellingShort /* modeling */ || !!intervention?.timeTillBenefits
      || !!intervention?.implementationTime /* implementationNotes */ || !!intervention?.nbsLifetime
      || !!intervention?.nbsMaintenance || !!intervention.cautions),
    nbsSuitability: !!intervention?.interventionIndicators,
    sources: (!!intervention?.interventionReferences || !!intervention?.datasets
      || !!intervention?.contributor)
  });

  // Header repeats on every page except the very first one
  // Footer repeats on every page

  return (
    <Page
      orientation="portrait"
      size="A4"
      style={Styles.page}
    >
      <Header title={intervention.name} />
      <Title name={intervention.name} description={intervention.desc} />

      <Overview intervention={intervention} />

      {!!tabs.design && (
        <Design intervention={intervention} />
      )}

      {!!tabs.nbsSuitability && (
        <Suitability intervention={intervention} />
      )}

      {!!tabs.sources && (
        <Sources intervention={intervention} />
      )}

      <Footer />
    </Page>
  );
};